<template>
  <div class="intro">
    <div class="section section01">
      <div class="bg">
        <div class="container">
          <home-header />
          <div class="content">
            <div class="text">
              <h3>功能强大的<br />专属知识学堂</h3>
              <div class="slogan">免费搭建小程序和PC端独立讲堂</div>
              <p>
                免费的教学空间，师资可自主开设微校，微课堂、直播平台、组织线上教学,支持小程序、PC等方式
              </p>
              <div class="btn" @click="toUse">免费使用</div>
            </div>
            <img src="@/assets/home/intro_s1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="section section02">
      <ul class="container">
        <li>
          <img src="@/assets/home/intro_s2_1.png" alt="" />
          <div class="text">
            <div class="title">丰富的知识变现方式</div>
            <p>
              以课程、直播等方式，帮助师资打造线上金课，免费为师资引流吸粉，免费为师资制作宣传片打造IP和学术网红，实现知识变现
            </p>
          </div>
        </li>
        <li>
          <div class="text">
            <div class="title">线上预约线下培训服务</div>
            <p>
              帮助师资打造线下精品课程，免费对接客户资源，针对客户需求，精准研发课程内容，提高线下培训收入
            </p>
          </div>
          <img src="@/assets/home/intro_s2_2.png" alt="" />
        </li>
        <li>
          <img src="@/assets/home/intro_s2_3.png" alt="" />
          <div class="text">
            <div class="title">智能收益报表&资金流水</div>
            <p>
              自动分析店铺收益和资金情况，呈现用户活跃及客户复购趋势；清晰查看在售商品类别数量、以及单个商品访问和付费情况，实现数字化运营管理。
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="section section03">
      <div class="content">
        <div class="title">帮助师资获取全网流量</div>
        <ul>
          <li>
            <img src="@/assets/home/intro_s3_icon1.png" alt="" />
            <p>免费宣传，打造品牌形象</p>
            <p>提升行业内影响和知名度</p>
          </li>
          <li>
            <img src="@/assets/home/intro_s3_icon2.png" alt="" />
            <p>免费引流</p>
            <p>降低招生成本</p>
          </li>
          <li>
            <img src="@/assets/home/intro_s3_icon3.png" alt="" />
            <p>精准抓取目标用户</p>
            <p>提高招生效果</p>
          </li>
        </ul>
        <div class="btn" @click="toUse">免费使用</div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="foot">
        <div class="foot_m">
          <div class="item-msd">
            <div class="font-Comp">
              <img src="@/assets/home/logo_zc.png" />
              <div>
                <p>湖北中潮教育科技集团有限公司</p>
                <p class="des">China Trend Educational Technology Group Co. LTD</p>
              </div>
            </div>
            <div class="items-jhp">
              <img src="@/assets/login/Pic-mhd.png" />
              <div>
                <p class="font-Dec">明商道——创建企业大学，占领商道制高点</p>
                <p class="font-So">全方位一站式内培平台，搭建持续学习能力提升的生态系统</p>
              </div>
            </div>
          </div>
          <div class="item-qt">
            <div class="items-jhs">
              <img src="@/assets/login/Pic-mzd.png" />
              <div>
                <p class="font-Dec">明职道——弘扬职业精神 打造职道竞争力</p>
                <p class="font-So">过千门各行各业的视频课程，助您职场竞争力提升MAX</p>
              </div>
            </div>
            <div class="items-jh">
              <img src="@/assets/login/Pic-msd.png" />
              <div>
                <p class="font-Dec">明师道——重构知识体系 引领师道新潮流</p>
                <p class="font-So">发布您的优秀课程，拥有千万学子，完成传道授业解惑</p>
              </div>
            </div>
          </div>
          <div class="item-gzh">
            <img src="@/assets/login/logo-wm.png" />
            <p>中潮教育服务号</p>
          </div>
          <div class="item-ewm">
            <img src="@/assets/home/mpcode.png" />
            <p>明师道小程序二维码</p>
          </div>
        </div>
        <div class="foot-n">
          <a href="https://beian.miit.gov.cn/#/Integrated/index">
            Copyright ©2015-2021 版权所有湖北中潮教育科技集团有限公司
            鄂ICP备15017598号-1（中潮教育融e学）
          </a>
          <p class="Powerby">Powered by 中潮教育</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HomeHeader from './components/HomeHeader'
import { getCookie, setCookie } from '@/utils/cookies'
export default {
  metaInfo() {
    return {
      title: this.$route.meta.title + '-明师道_帮助培训机构和师资端进行知识变现-中潮教育',
      meta: [
        {
          name: 'keyWords',
          content: '明商道,知识店铺,课程变现,在线课程发布平台,培训机构课程发布,精品课程发布',
        },
        {
          name: 'description',
          content:
            '融E学·明师道-零门槛打造专属知识店铺，创建品牌，发布课程，为师资、培训机构提供一体化解决方案。',
        },
      ],
    }
  },
  name: 'HomeIntro',
  components: { HomeHeader },
  data() {
    return {
      token: getCookie('myToken'),
    }
  },
  methods: {
    async toUse() {
      if (!this.token) {
        setCookie('redirectUrl', window.location.href)
        window.location.href = process.env.VUE_APP_LOGIN_CENTER
      } else {
        this.$router.push('/createCollege')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.intro {
  width: 100%;
  .section .container {
    height: 100%;
    width: 1200px;
    margin: 0 auto;
  }
  .section01 {
    width: 100%;
    height: 550px;
    background: linear-gradient(90deg, #15b894 50%, #5ec2ab 50%);
    .header {
      margin-bottom: 40px;
    }
    .bg {
      width: 100%;
      height: 100%;
      background: url('../../assets/home/s1_bg.png') no-repeat center;
    }
    .content {
      display: flex;
      justify-content: space-between;
      .text {
        width: 442px;
        color: #ffffff;
        padding-top: 28px;
        h3 {
          font-size: 48px;
          font-weight: 700;
          margin-bottom: 16px;
        }
        .slogan {
          font-size: 30px;
          margin-bottom: 9px;
          font-weight: 500;
          color: #ffffff;
        }
        p {
          font-size: 20px;
          color: #ffffff;
          margin-bottom: 29px;
        }
        .btn {
          cursor: pointer;
          width: 307px;
          height: 60px;
          line-height: 60px;
          background: #f8b551;
          border-radius: 10px;
          font-size: 25px;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
        }
      }
      img {
        width: 562px;
        height: 390px;
      }
    }
  }
  .section02 {
    padding: 35px 0 40px;
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-size: 24px;
        font-weight: 700;
        color: #15b894;
        line-height: 48px;
        margin-bottom: 16px;
      }
      p {
        font-size: 18px;
        color: #343434;
        line-height: 36px;
        font-weight: 400;
      }
      &:nth-of-type(1) {
        margin-bottom: 15px;
        p {
          width: 679px;
        }
        img {
          width: 420px;
          height: 253px;
        }
      }
      &:nth-of-type(2) {
        margin-bottom: 16px;
        p {
          width: 605px;
        }
        img {
          width: 420px;
          height: 267px;
        }
      }
      &:nth-of-type(3) {
        p {
          width: 679px;
        }
        img {
          width: 450px;
          height: 268px;
        }
      }
    }
  }
  .section03 {
    height: 431px;
    background: linear-gradient(0deg, #15b894 0%, #5ec2ab 100%);
    .content {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      background: url('../../assets/home/intro_s3_bg.png') no-repeat center 10px;
      padding-top: 42px;
      .title {
        width: 682px;
        position: relative;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
        line-height: 42px;
        margin: 0 auto;
        margin-bottom: 43px;
        &::before {
          position: absolute;
          left: 0;
          top: 20px;
          content: '';
          width: 155px;
          height: 2px;
          background: #ffffff;
        }
        &::after {
          position: absolute;
          right: 0;
          top: 20px;
          content: '';
          width: 155px;
          height: 2px;
          background: #ffffff;
        }
      }
      .btn {
        width: 307px;
        cursor: pointer;
        height: 60px;
        line-height: 60px;
        background: #f8f9fc;
        border-radius: 10px;
        font-size: 24px;
        font-weight: 500;
        text-align: center;
        color: #1fb997;
        margin: 0 auto;
      }
      ul {
        display: flex;
        margin-bottom: 40px;
        justify-content: center;
        li {
          text-align: center;
          margin-right: 172px;
          &:last-of-type {
            margin: 0;
          }
          img {
            width: 80px;
            height: 80px;
            margin-bottom: 22px;
          }
          p {
            font-size: 18px;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }

  .footer {
    box-sizing: border-box;
    height: 351px;
    background: #333333;
    padding-top: 25px;
  }
  .foot {
    width: 100%;
  }
  .footer a {
    color: #949494;
  }
  .foot_m {
    display: flex;
    justify-content: center;
  }
  .item-msd {
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    text-align: left;
  }
  .font-Comp {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    img {
      width: 62px;
      height: 62px;
      margin-right: 14px;
    }
    .des {
      font-size: 12px;
    }
  }
  .font-Dec {
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    padding-top: 11px;
  }
  .font-So {
    font-size: 12px;
    color: #ffffff;
    line-height: 17px;
  }
  .items-jh {
    margin-top: 33px;
    display: flex;
  }
  .items-jh img {
    width: 62px;
    height: 62px;
    margin-right: 12px;
  }
  .item-gzh {
    margin-left: 86px;
    margin-top: 5px;
  }
  .item-gzh img {
    display: block;
    width: 126px;
    height: 126px;
    margin-bottom: 9px;
  }
  .item-ewm {
    margin-left: 14px;
    margin-top: 5px;
  }

  .item-ewm img {
    display: block;
    width: 126px;
    height: 126px;
    margin-bottom: 9px;
  }
  .item-gzh p,
  .item-ewm p {
    text-align: center;
    font-size: 12px;
    line-height: 12px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #fff;
  }
  .item-qt {
    margin-left: 82px;
  }
  .items-jhs {
    display: flex;
  }
  .items-jhs img {
    width: 62px;
    height: 62px;
    margin-right: 12px;
  }
  .items-jhp {
    display: flex;
    margin-top: 33px;
  }
  .items-jhp img {
    width: 62px;
    height: 62px;
    margin-right: 12px;
  }
  .foot-n {
    margin-top: 58px;
    text-align: center;
  }
  .help {
    font-size: 12px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #949494;
  }
  .help span {
    margin-left: 63px;
  }
  .foot-n a {
    display: block;
    text-decoration: none;
    font-size: 12px;
    margin-top: 5px;
  }
  .Powerby {
    font-size: 12px;
    color: #949494;
    text-align: center;
    margin-top: 5px;
  }
}
</style>
